import { useRouter } from 'next/router'
import React from 'react'

import cx from 'classnames'

import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'

import { IS_BROWSER } from '~/constants'

import { useHeaderStyles } from './Header.style'
import HeaderView from './Header.view'
import { navLinks } from './links'

const Header: React.FC = (): JSX.Element => {
  const router = useRouter()
  const classes = useHeaderStyles()

  const isHomePage = router.pathname === '/'

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: IS_BROWSER && isHomePage ? window : undefined
  })

  return (
    <Box className={!isHomePage && classes.root}>
      <Box display="none">
        <nav itemScope itemType="http://schema.org/SiteNavigationElement">
          <ul>
            {navLinks.map((link) => (
              <li key={link.href}>
                <a itemProp="url" href={link.href}>
                  <span itemProp="name">{link.children}</span>
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </Box>
      <AppBar
        position="fixed"
        component="header"
        className={cx(classes.common, {
          [classes.homepage]: isHomePage,
          [classes.default]: !isHomePage,
          [classes.homepageScroll]: trigger && isHomePage,
          [classes.scroll]: trigger && !isHomePage
        })}
        style={{ zIndex: 1299 }}
      >
        <HeaderView />
      </AppBar>
    </Box>
  )
}

export default Header
