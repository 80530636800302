import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const headerHeight = 85
export const mobileHeaderHeight = 55

export const useHeaderStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        [theme.breakpoints.down('md')]: {
          height: mobileHeaderHeight
        },
        [theme.breakpoints.up('lg')]: {
          height: headerHeight
        }
      },
      common: {
        boxShadow: 'none',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: 2,
        '& .nav-link': {
          '&:first-child': {
            // marginLeft: 0
          },
          '&:last-child': {
            marginRight: 0
          }
        },
        '& .loggedLink, .nav-link > a': {
          position: 'relative',
          fontFamily: 'Prompt, sans-serif'
        },
        '& .loggedLink::before, .nav-link > a::before': {
          content: "''",
          position: 'absolute',
          bottom: '1px',
          left: 0,
          height: '2px',
          width: '20px',
          background: 'transparent',
          transform: 'translate3d(-100%,0,1px)',
          transformOrigin: 'left',
          transition: 'transform .5s cubic-bezier(.215,.61,.355,1)'
        },
        '& .loggedLink:hover::before, .nav-link > a:hover::before': {
          transform: 'translateZ(2px)',
          background: theme.palette.common.black
        },
        '& .btnHeader': {
          borderColor: theme.palette.text.secondary,
          fontSize: 16,
          height: 30,
          '& .textBtnHeader': {
            color: theme.palette.text.primary,
            fontSize: 14
          }
        },
        '& .header': {
          [theme.breakpoints.down('md')]: {
            height: mobileHeaderHeight
          },
          [theme.breakpoints.up('lg')]: {
            height: headerHeight,
            '& .menuBtn': {
              display: 'none'
            }
          }
        },
        [theme.breakpoints.down('md')]: {
          zIndex: 1299,
          background: theme.palette.common.white,
          boxShadow: theme.shadows[4]
        }
      },
      homepage: {
        '& .btnHeader': {
          borderColor: theme.palette.common.white,
          [theme.breakpoints.down('md')]: {
            borderColor: theme.palette.common.black
          },
          '& .textBtnHeader': {
            color: theme.palette.common.white,
            [theme.breakpoints.down('md')]: {
              color: theme.palette.common.black
            }
          },
          '&:hover': {
            backgroundColor: theme.palette.common.white,
            '& .textBtnHeader': {
              color: theme.palette.primary.main
            }
          }
        },
        '& .textBtnHeader': {
          color: theme.palette.common.white
        },
        '& .loggedLink, .nav-link > a': {
          [theme.breakpoints.up('lg')]: {
            color: theme.palette.common.white
          }
        },
        '& .loggedLink:hover::before, .nav-link > a:hover::before': {
          background: theme.palette.common.white
        },
        [theme.breakpoints.up('lg')]: {
          background: 'transparent'
        }
      },
      default: {
        background: theme.palette.common.white,
        '& .nav-link > a:not(.active):hover, .loggedLink': {
          color: theme.palette.text.primary
        }
      },
      homepageScroll: {
        [theme.breakpoints.up('lg')]: {
          background: theme.palette.common.white,
          '& .loggedLink, .nav-link > a': {
            color: theme.palette.text.primary
          },
          '& .btnHeader': {
            borderColor: theme.palette.text.primary,
            '& .textBtnHeader': {
              color: theme.palette.text.primary
            }
          },
          '& .textBtnHeader': {
            color: theme.palette.text.primary
          },
          '& .loggedLink:hover::before, .nav-link > a:hover::before': {
            background: theme.palette.common.black
          },
          transition: 'background 0.5s ease',
          boxShadow: theme.shadows[4]
        }
      },
      scroll: {
        [theme.breakpoints.up('lg')]: {
          boxShadow: theme.shadows[4],
          transition: 'background 0.5s ease'
        }
      }
    }),
  {
    name: 'HeaderDesktop'
  }
)
