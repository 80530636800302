import dynamic from 'next/dynamic'
import React, { useCallback, useEffect, useState } from 'react'

import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'

import useIdleCallback from '~/hooks/useIdleCallback'

const MenuMobile = dynamic(() =>
  import('~/components/layouts/Header/HeaderMenu/HeaderMenu')
)

interface MenuButtonProps {
  mobile: boolean
}

const MenuButton = ({ mobile }: MenuButtonProps) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  return (
    <>
      <IconButton
        className="menuBtn"
        aria-label={isOpenMenu ? 'close drawer' : 'open drawer'}
        onClick={() => {
          setIsOpenMenu((prev) => !prev)
        }}
      >
        {isOpenMenu ? <CloseIcon /> : <MenuIcon />}
      </IconButton>

      {mobile && (
        <LazyMenuMobile
          open={isOpenMenu}
          onChange={setIsOpenMenu}
          mounted={isOpenMenu}
        />
      )}
    </>
  )
}

const LazyMenuMobile = ({
  open,
  onChange,
  mounted = false
}: {
  open: boolean
  onChange: (...props: any) => void
  mounted: boolean
}) => {
  const [isMounted, setMounted] = useState(mounted)

  useIdleCallback(
    useCallback(() => {
      setMounted(true)
    }, [])
  )

  useEffect(() => {
    if (mounted) {
      setMounted(mounted)
    }
  }, [mounted])

  return isMounted && <MenuMobile isOpen={open} setIsOpen={onChange} />
}

export default MenuButton
