import React, { FC, useMemo, useState } from 'react'

import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem'
import { makeStyles, createStyles } from '@material-ui/core/styles'

import Link from '~/components/shared/Link'

import { Theme } from '~/theme/theme'

interface IMenuItem extends MenuItemProps {
  key: string
  child: string | JSX.Element
  href?: string
  onClick?: (props: any) => void
  [key: string]: any
}

interface Props extends Partial<Omit<MenuProps, 'onChange'>> {
  menuItems: IMenuItem[]
  children: (props: {
    onClick: (e: React.MouseEvent<HTMLElement>) => void
    open: boolean
    child: string | JSX.Element
  }) => React.ReactNode
  defaultValue?: any
  onChange?: (value: IMenuItem) => void
  popupMarginTop?: number | string
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      menuItemDealer: {
        color: theme.palette.primary.main,
        fontWeight: 700
      },

      menuItemSignOut: {
        opacity: 0.5
      }
    }),
  { name: 'ProfileDropDownMenu' }
)

const DropdownMenu: FC<Props> = ({
  children,
  menuItems,
  defaultValue,
  onChange,
  popupMarginTop = 0,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const defaultItem = useMemo(
    () => menuItems.find(({ key }) => defaultValue === key) || menuItems[0],
    [menuItems, defaultValue]
  )
  const [activeItem, setItem] = useState(defaultItem)
  const [isMount, setIsMount] = useState(false)

  const classes = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
    setIsMount(true)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      {children({
        onClick: handleClick,
        open: Boolean(anchorEl),
        child: activeItem.child
      })}
      {isMount && (
        <Menu
          style={{ marginTop: popupMarginTop }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          {...props}
        >
          {menuItems.map((item, index) => {
            const { key, onClick, href, ...rest } = item
            const isDealerItem = item.key === 'delaer'
            const isSignOut = item.key === 'logout'
            const dealerStyle = isDealerItem && classes.menuItemDealer
            const signOutStyle = isSignOut && classes.menuItemSignOut
            const menuItemClass = dealerStyle || signOutStyle
            const handleMenuItemClick = (): void => {
              if (onClick) {
                onClick(item)
              }
              if (onChange) {
                onChange(item)
              }
              setItem(item)
              handleClose()
            }

            const menuItem = (
              <MenuItem
                // @ts-ignore
                component="li"
                {...rest}
                key={`${key}_${index}`}
                className={menuItemClass}
                onClick={handleMenuItemClick}
              >
                {item.child}
              </MenuItem>
            )

            if (href) {
              return (
                <Link href={href} inherit key={`${key}_${index}`}>
                  {menuItem}
                </Link>
              )
            }
            return menuItem
          })}
        </Menu>
      )}
    </>
  )
}

export default DropdownMenu
