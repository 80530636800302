import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useAccountMenuStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      accountButton: {
        height: 30,
        width: 108,
        backgroundColor: theme.palette.bg.primary.dark,
        borderRadius: '4px'
      }
    }),
  { name: 'RecentlyAddedSection' }
)
