import { Theme, makeStyles, StyleRules } from '@material-ui/core/styles'

const linkStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    root: {
      cursor: 'pointer',
      color: theme.palette.text.primary,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none !important',
        color: theme.palette.text.secondary
      },

      '&.active': {
        color: theme.palette.primary.main
      },

      '&.footer': {
        display: 'block',
        fontSize: 14,
        marginLeft: 0,
        marginBottom: 15,
        '&:last-child': {
          marginBottom: 0
        }
      }
    }
  })
)

export default linkStyles
