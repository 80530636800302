import React from 'react'

import { Chip } from '@material-ui/core'

import { UserState } from '~/state/modules/user/user.slice'
import Paths from '~/utils/paths'

export const buildMenuItems = (user: UserState) => {
  const isDealer = user.role === 'dealer'
  const dealerDraft = user.dealer?.status === 'draft'
  // const businessDraft = user.business?.status === 'draft'
  const dealerProcessing = user.dealer?.status === 'payment_processing'
  // const businessProcessing = user.business?.status === 'payment_processing'

  const EditDealer = (
    <span>
      Edit dealer
      {(dealerDraft || dealerProcessing) && (
        <Chip
          label={dealerDraft ? 'Pending payment' : 'Waiting for approval'}
          size="small"
          variant="outlined"
          style={{ marginLeft: 8 }}
        />
      )}
    </span>
  )

  // const EditBusiness = (
  //   <span>
  //     My business
  //     {(businessDraft || businessProcessing) && (
  //       <Chip
  //         label={businessDraft ? 'Pending payment' : 'Waiting for approval'}
  //         size="small"
  //         variant="outlined"
  //         style={{ marginLeft: 8 }}
  //       />
  //     )}
  //   </span>
  // )

  return [
    {
      key: 'dashboard',
      child: 'Dashboard',
      href: Paths.account.dashboard
    },
    {
      key: 'my-ads',
      child: 'My Ads',
      href: Paths.account.ads
    },
    {
      key: 'enquiries',
      child: 'Enquiries',
      href: Paths.account.enquiries
    },
    {
      key: 'saved-items',
      child: 'Saved Items',
      href: Paths.account.savedItems
    },
    {
      key: 'saved-searches',
      child: 'Saved Searches',
      href: Paths.account.savedSearches
    },
    // {
    //   key: 'business',
    //   child: user.business ? EditBusiness : 'Register my business',
    //   href: user.business ? Paths.businessess.edit : Paths.businessess.new
    // },
    {
      key: 'delaer',
      child: isDealer ? EditDealer : 'Become a dealer',
      href: isDealer ? Paths.dealer.edit : Paths.dealer.new
    },
    {
      key: 'personal-info',
      child: 'Personal Info',
      href: Paths.account.profile,
      divider: true
    }
  ]
}
