import { useRouter } from 'next/router'
import React from 'react'

import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'

import ExtendedButton from '~/components/shared/ExtendedButton'
import Text from '~/components/shared/Text'

import { isLogged } from '~/state/modules/user'
import Paths from '~/utils/paths'

const SellButton = () => {
  const router = useRouter()
  const logged = useSelector(isLogged)

  const handleClick = () => {
    if (logged) {
      router.push(Paths.sell.index)
    } else {
      router.push(Paths.package.index)
      toast.error('You need to be logged in to create listings.')
    }
  }

  return (
    <ExtendedButton
      variant="outlined"
      color="default"
      size="medium"
      onClick={handleClick}
      className="btnHeader"
    >
      <Text
        component="div"
        textTransform="none"
        color="textPrimary"
        fontSize={16}
        className="textBtnHeader"
        whiteSpace="nowrap"
      >
        Create Ad
      </Text>
    </ExtendedButton>
  )
}

export default SellButton
