import RouterLink, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import cx from 'classnames'

import { Link, Box } from '@material-ui/core'

import { Title } from '../Text'

import useStyles from './link.styles'

type NavLinkProps = LinkProps & {
  children: string | JSX.Element | JSX.Element[]
  href: string
  as?: string
  footer?: boolean
  onClick?: () => void
  exact?: boolean
  className?: string
}

const NavLink: React.FC<NavLinkProps> = ({
  href = '/',
  as,
  children,
  footer,
  exact,
  className,
  ...props
}) => {
  const classes = useStyles()
  const router = useRouter()
  let linkClassName = classes.root

  if (
    exact
      ? router.pathname === href
      : new RegExp(`^${href}`, 'gmi').test(router.pathname)
  ) {
    linkClassName = `${linkClassName} active`
  }

  return (
    <RouterLink href={href} passHref as={as}>
      <Link className={cx(linkClassName, className, { footer })} {...props}>
        <Box display="inline-flex">
          <Title fontSize={16} fontWeight={400}>
            {children}
          </Title>
        </Box>
      </Link>
    </RouterLink>
  )
}

export default NavLink
