import Image, { ImageProps } from 'next/image'
import React from 'react'

import { NoSsr } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import NavLink from '~/components/shared/NavLink/NavLink'

import LogoBoatMobile from '~/assets/icons/logo-boat-mini.svg?sprite'
import LogoRvMobile from '~/assets/icons/logo-rv-mini.svg?sprite'
import LogoBoat from '~/assets/images/boat-powered-logo.svg'
import LogoRv from '~/assets/images/rv-powered-logo.svg'

import { getEntityByBrand, IS_BOAT } from '~/brands'

const logoSrc = getEntityByBrand<string>({ rv: LogoRv, boat: LogoBoat })

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('lg')]: {
        display: 'block',
        width: 220
      }
    }
  })
)

interface LogoProps extends Omit<ImageProps, 'src'> {
  isMobile?: boolean
  width?: number
  height?: number
}

const Logo = ({ isMobile, ...props }: LogoProps) => {
  const classes = useStyles()

  return (
    <NavLink href="/" passHref className={classes.root}>
      <NoSsr>
        {isMobile && (IS_BOAT ? <LogoBoatMobile /> : <LogoRvMobile />)}
        {!isMobile && (
          <Image
            src={logoSrc}
            layout="fixed"
            priority
            loading="eager"
            width={220}
            height={76}
            alt="logo"
            {...props}
          />
        )}
      </NoSsr>
    </NavLink>
  )
}

export default Logo
