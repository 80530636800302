import Paths from '~/utils/paths'

import { getEntityByBrand } from '~/brands'

const RVNavLinks = [
  {
    href: Paths.search.index,
    children: 'RVs for sale'
  },
  {
    href: Paths.package.index,
    children: 'Sell your RV'
  },
  {
    href: Paths.newsReviews.index,
    children: 'News & Reviews'
  },
  {
    href: Paths.advertising.index,
    children: 'Dealer Advertising'
  },
  {
    href: Paths.help.index,
    children: 'Help & FAQs'
  }
]

const BoatNavLinks = [
  {
    href: Paths.search.index,
    children: 'Boats for sale'
  },
  {
    href: Paths.package.index,
    children: 'Sell your Boat'
  },
  {
    href: Paths.newsReviews.index,
    children: 'News & Reviews'
  },
  {
    href: Paths.advertising.index,
    children: 'Dealer Advertising'
  },
  {
    href: Paths.help.index,
    children: 'Help & FAQs'
  }
]

type NavLinks = typeof BoatNavLinks | typeof RVNavLinks

export const navLinks = getEntityByBrand<NavLinks>({
  rv: RVNavLinks,
  boat: BoatNavLinks
})

const RVLinks = [
  {
    key: 'caravans',
    children: 'Caravans'
  },
  {
    key: 'pop-tops',
    children: 'Pop tops'
  },
  {
    key: 'expander',
    children: 'Expander'
  },
  {
    key: 'hybrids',
    children: 'Hybrids'
  },
  {
    key: 'camper-trailer',
    children: 'Camper Trailer'
  },
  {
    key: 'motorhomes',
    children: 'Motorhomes'
  },
  {
    key: 'vehicles',
    children: 'Vehicles'
  },
  {
    key: 'parts-and-accessories',
    children: 'Parts & Accessories'
  }
]

const BoatLinks = [
  {
    key: 'boats',
    children: 'Boats'
  },
  {
    key: 'marine-accessories',
    children: 'Marine Accessories'
  },
  {
    key: 'marine-parts',
    children: 'Marine Parts'
  },
  {
    key: 'marine-storage',
    children: 'Marine Storage'
  },
  {
    key: 'marine-trailers',
    children: 'Marine Trailers'
  },
  {
    key: 'marine-engines',
    children: 'Marine Engines'
  }
]

type Links = typeof BoatLinks | typeof RVLinks

export const links = getEntityByBrand<Links>({
  rv: RVLinks,
  boat: BoatLinks
})
