export const daysOfWeek = [
  { label: 'Sunday', value: 'sunday' },
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' }
]

export const statesOptions = [
  'ACT',
  'VIC',
  'NSW',
  'QLD',
  'SA',
  'WA',
  'TAS',
  'ACT',
  'NT'
]

export const saleStatusOptions = [
  { label: 'For Sale', value: 0 },
  { label: 'Per Share', value: 1 }
]

export const saleStatus = {
  for_sale: 0,
  for_share: 1
}

export const countriesOptions = [{ value: 'Australia', label: 'Australia' }]

export const sorting = {
  price_low_to_high: 'Price (lowest to highest)',
  price_high_to_low: 'Price (highest to lowest)',
  newest: 'Most Recent',
  last_updated: 'Last Updated',
  oldest: 'Oldest'
}

export const conditionOptions = [
  { value: 'New & Used', label: 'New & Used' },
  { value: 'New', label: 'New' },
  { value: 'Used', label: 'Used' }
]

export const colorOptions = [
  { value: 'White', label: 'White' },
  { value: 'Black', label: 'Black' },
  { value: 'Blue', label: 'Blue' },
  { value: 'Silver', label: 'Silver' },
  { value: 'Other', label: 'Other' }
]

export const numberOfBerths = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  { value: '10', label: '10+' }
]

export const sellerTypeOptions = [
  { value: 'Dealer', label: 'Dealer' },
  { value: 'User', label: 'Private' }
]

export const loanTermOptions = [
  { label: '1 year', value: '1' },
  { label: '2 years', value: '2' },
  { label: '3 years', value: '3' },
  { label: '4 years', value: '4' },
  { label: '5 years', value: '5' },
  { label: '6 years', value: '6' },
  { label: '7 years', value: '7' }
]

export const paymentFreqOptions = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' }
]
