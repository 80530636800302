import { useEffect, useRef } from 'react'

/*
  reference https://github.com/Shopify/quilt/tree/main/packages/react-idle
*/

type ExtendedWindow<T> = Window & typeof globalThis & T

export enum UnsupportedBehavior {
  Immediate,
  AnimationFrame
}

export type RequestIdleCallbackHandle = any

export interface RequestIdleCallbackOptions {
  timeout: number
}

export interface RequestIdleCallbackDeadline {
  readonly didTimeout: boolean
  timeRemaining(): number
}

export type RequestIdleCallback = (
  deadline: RequestIdleCallbackDeadline
) => void

export interface WindowWithRequestIdleCallback extends Window {
  requestIdleCallback(
    callback: RequestIdleCallback,
    opts?: RequestIdleCallbackOptions
  ): RequestIdleCallbackHandle
  cancelIdleCallback: (handle: RequestIdleCallbackHandle) => void
}

function useIdleCallback(
  callback: () => void,
  { unsupportedBehavior = UnsupportedBehavior.AnimationFrame } = {}
) {
  const handle = useRef<RequestIdleCallbackHandle | null>(null)

  useEffect(() => {
    if ('requestIdleCallback' in window) {
      handle.current = (window as ExtendedWindow<
        WindowWithRequestIdleCallback
      >).requestIdleCallback(() => callback())
    } else if (unsupportedBehavior === UnsupportedBehavior.AnimationFrame) {
      handle.current = window.requestAnimationFrame(() => {
        callback()
      })
    } else {
      callback()
    }

    return () => {
      const { current: currentHandle } = handle
      handle.current = null

      if (currentHandle == null) {
        return
      }

      if ('cancelIdleCallback' in window) {
        ;(window as ExtendedWindow<
          WindowWithRequestIdleCallback
        >).cancelIdleCallback(currentHandle)
      } else if (unsupportedBehavior === UnsupportedBehavior.AnimationFrame) {
        window.cancelAnimationFrame(currentHandle)
      }
    }
  }, [callback, unsupportedBehavior])
}

export default useIdleCallback
