import React, { useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Box, Link } from '@material-ui/core'

import DropdownMenu from '~/components/shared/DropdownMenu'

import { logout } from '~/state/modules/user'
import { AppState } from '~/state/store'

import ArrowIcon from '~/assets/icons/arrow-down.svg?sprite'
import { buildMenuItems } from '~/data/menuItems'

import Flexbox from '../../shared/Flexbox'
import Text from '../../shared/Text'

import { useAccountMenuStyles } from './AccountMenu.styles'

const AccountMenu: React.FC = () => {
  const s = useAccountMenuStyles()
  const dispatch = useDispatch()
  const user = useSelector((state: AppState) => state.user)

  const menuItems = useMemo(
    () => [
      ...buildMenuItems(user),
      {
        key: 'logout',
        child: 'Sign Out',
        onClick: () => dispatch(logout())
      }
    ],
    [user]
  )

  return (
    <DropdownMenu menuItems={menuItems} popupMarginTop={45}>
      {({ onClick }): JSX.Element => (
        <Link
          style={{ cursor: 'pointer' }}
          component="span"
          onClick={onClick}
          underline="none"
          color="textPrimary"
        >
          <Box className={s.accountButton}>
            <Flexbox>
              <Text fontSize={14} p={0.5} pl={1}>
                My Account
              </Text>

              <ArrowIcon style={{ alignSelf: 'center' }} />
            </Flexbox>
          </Box>
        </Link>
      )}
    </DropdownMenu>
  )
}

export default AccountMenu
