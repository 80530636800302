import React from 'react'

import { Button, CircularProgress, Box } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import {
  makeStyles,
  createStyles,
  darken,
  styled,
  Theme
} from '@material-ui/core/styles'
import {
  spacing,
  sizing,
  compose,
  ComposedStyleFunction,
  PropsFor
} from '@material-ui/system'

type TextStyleFunction = ComposedStyleFunction<[typeof spacing, typeof sizing]>

type SystemProps = PropsFor<TextStyleFunction>

type StylesProps = {
  bgcolor?: 'error' | 'success' | string | undefined
  loading?: boolean
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        pointerEvents: (props: StylesProps) => (props.loading ? 'none' : 'auto')
      },
      loader: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: `translate(-50%, -50%)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      contained: {
        color: (props: StylesProps): string => {
          if (!props.bgcolor) return ''

          if (theme.palette[props.bgcolor]) {
            return theme.palette.getContrastText(
              theme.palette[props.bgcolor].main
            )
          }

          return theme.palette.getContrastText(props.bgcolor)
        },
        backgroundColor: (props: StylesProps): string => {
          if (!props.bgcolor) return ''

          if (theme.palette[props.bgcolor]) {
            return theme.palette[props.bgcolor].main
          }

          return props.bgcolor
        },

        '&:hover': {
          backgroundColor: (props: StylesProps): string => {
            if (!props.bgcolor) return ''

            if (theme.palette[props.bgcolor]) {
              return (
                theme.palette[props.bgcolor].dark ||
                darken(theme.palette[props.bgcolor].main, 0.2)
              )
            }

            return darken(props.bgcolor, 0.2)
          }
        }
      }
    }),
  { name: 'ExtButton' }
)

export interface ExtButtonProps extends ButtonProps, SystemProps {
  children: string | JSX.Element | JSX.Element[] | React.ReactNode
  loading?: boolean
  round?: boolean
  bgcolor?: 'error' | 'success' | string | undefined
}

const ExtButton: React.FC<ExtButtonProps> = ({
  children,
  loading,
  bgcolor,
  round,
  onClick,
  ...props
}) => {
  const classes = useStyles({ bgcolor, loading })
  const contained =
    bgcolor &&
    (props.variant === 'contained' || !props.variant) &&
    !props.color &&
    classes.contained

  const colorPrimary = props.color === 'primary' || !props.color

  return (
    <Button
      size="large"
      variant="contained"
      color={!contained ? 'primary' : 'default'}
      className={classes.root}
      classes={{
        contained
      }}
      style={round ? { borderRadius: 50 } : {}}
      {...(!loading && { onClick })}
      {...props}
    >
      <Box
        component="span"
        display="inline-flex"
        alignItems="center"
        visibility={loading ? 'hidden' : 'visible'}
      >
        {children}
      </Box>
      {loading && (
        <Box className={classes.loader}>
          <CircularProgress
            color={!colorPrimary ? 'primary' : 'secondary'}
            size={26}
          />
        </Box>
      )}
    </Button>
  )
}

const ExtendedButton = styled(ExtButton)(compose(spacing, sizing), {
  name: 'ExtendedButton'
})

export default ExtendedButton
