// @ts-nocheck
import { isSafari } from '~/constants/devices'

let numeral
// load numerall lib when Safari
const getNumeral = async () => {
  if (!numeral) {
    numeral = (await import('numeral')).default
  }

  return numeral
}

if (isSafari) {
  getNumeral()
}

/**
 * Intl service
 */
class IntlService {
  /**
   * Number formatter
   *
   * @param value formatted value
   * @param options Intl.NumberFormatOptions
   */
  static numberFormat(
    value: string | number,
    options?: Intl.NumberFormatOptions
  ): string {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      ...options
    })

    return formatter.format(Number(value))
  }

  /**
   * Currency formatter
   *
   * @param value formatter value
   * @param options Intl.NumberFormatOptions
   */
  static currencyFormat(
    value: string | number,
    options?: Intl.NumberFormatOptions
  ): string {
    const formatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      ...options
    })

    return formatter.format(Number(value)).replace('A$', '$')
  }

  /**
   * Compact formatter
   *
   * @param value formatter value
   * @param options Intl.NumberFormatOptions
   */
  static compactFormat(
    value: string | number,
    options?: Intl.NumberFormatOptions
  ): string {
    if (isSafari && numeral) {
      return `A${numeral(value).format(value < 1000000 ? '($0a)' : '$0.0a')}`
    }

    const formatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'AUD',
      notation: 'compact',
      compactDisplay: 'short',
      ...options
    })

    return formatter.format(Number(value))
  }
}

export default IntlService
