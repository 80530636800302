import RouterLink, { LinkProps as RouterLinkProps } from 'next/link'
import React from 'react'

import { Link as MuiLink } from '@material-ui/core'
import { LinkProps } from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  inheritColor: {
    color: 'inherit'
  }
})

type Props = {
  href: string
  as?: RouterLinkProps['as']
  inherit?: boolean
  prefetch?: boolean
}

const Link: React.FC<Props & LinkProps> = ({
  href = '/',
  children,
  inherit,
  as,
  prefetch = true,
  ...props
}) => {
  const classes = useStyles()

  return (
    <RouterLink href={href} passHref prefetch={prefetch} as={as}>
      <MuiLink
        underline="none"
        // @ts-ignore
        className={inherit && classes.inheritColor}
        {...props}
      >
        {children}
      </MuiLink>
    </RouterLink>
  )
}

export default Link
