import React from 'react'

import { useSelector } from 'react-redux'

import { Box, Container, Theme, useMediaQuery } from '@material-ui/core'

import AccountMenu from '~/components/menu/AccountMenu/AccountMenu'
import Flexbox from '~/components/shared/Flexbox'
import Link from '~/components/shared/Link'
import Logo from '~/components/shared/Logo'
import NavLink from '~/components/shared/NavLink/NavLink'

import { isLogged } from '~/state/modules/user'
import Paths from '~/utils/paths'

import MenuButton from './HeaderMenu/MenuButton'
import { navLinks } from './links'
import SellButton from './SellButton'

const Header = (): JSX.Element => {
  const logged = useSelector(isLogged)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Container maxWidth="xl">
      <Flexbox
        alignItems="center"
        className="header"
        justifyContent="space-between"
      >
        <Flexbox alignItems="center">
          <Box className="header__logo" mr={{ xs: 0, md: 1 }}>
            <Logo isMobile={isMobile} />
          </Box>
          <Box
            className="header__navLinks"
            display={{ xs: 'none', md: 'flex' }}
          >
            {!isMobile &&
              navLinks.map(({ children, href, ...rest }) => (
                <Box className="nav-link" mx={{ xs: 1, lg: 2 }} key={href}>
                  <NavLink href={href} prefetch={false} {...rest}>
                    {children}
                  </NavLink>
                </Box>
              ))}
          </Box>
        </Flexbox>
        <Flexbox alignItems="center">
          <Box mr={{ xs: 2, lg: 3 }}>
            <SellButton />
          </Box>
          {logged ? (
            <AccountMenu />
          ) : (
            <Link
              href={Paths.auth.signIn}
              className="loggedLink"
              color="textPrimary"
            >
              Sign In
            </Link>
          )}
          <MenuButton mobile={isMobile} />
        </Flexbox>
      </Flexbox>
    </Container>
  )
}

export default Header
